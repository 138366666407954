import TopTags1 from "./assets/images/CategorySlider/TopTags1.png";
import TopTags2 from "./assets/images/CategorySlider/TopTags2.png";
import TopTags3 from "./assets/images/CategorySlider/TopTags3.png";
import TopTags4 from "./assets/images/CategorySlider/TopTags4.png";
import TopTags5 from "./assets/images/CategorySlider/TopTags5.png";
import TopTags7 from "./assets/images/CategorySlider/TopTags7.png";
import TopTags8 from "./assets/images/CategorySlider/TopTags8.png";

export const COLORS = {
  white: "#fff",
  black: "rgba(0, 0, 0, 1)",
  red: "rgba(248, 21, 57, 1)",
  lightGray: "rgba(62, 50, 50, 0.75)",
  grey: "#F5F5F5",
  lightGrayLighter: "rgba(125, 100, 100, 0.75)",
};

export const BOXSHADOW = "0px 2px 10px rgb(0 0 0 / 40%)";

export const TOP_CATEGORIES = [
  { name: "Новини", image: TopTags1 },
  { name: "Мода", image: TopTags2 },
  {
    name: "Събития",
    image: TopTags3,
  },
  { name: "Спорт", image: TopTags4 },
  { name: "Музика", image: TopTags5 },
  {
    name: "Хайлайф",
    image: TopTags7,
  },
  {
    name: "Лайфстайл",
    image: TopTags8,
  },
  {
    name: "Лайфстайл",
    image: TopTags3,
  },
  {
    name: "Лайфстайл",
    image: TopTags4,
  },
  {
    name: "Лайфстайл",
    image: TopTags5,
  },
];
