import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../constants";
import { useNavigate } from "react-router-dom";
import { trimTitle } from "../utils/helperFunctios";
import { GLOBAL_URL } from "../utils/Constant";

export default function Card1({ cardData }) {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Card className={classes.cardcontainer} sx={{ borderRadius: "12px" }}>
      <CardMedia
        component="img"
        height="100%"
        image={`${GLOBAL_URL}/${cardData?.bgImg}`}
        alt="Paella dish"
      />
      <CardContent className={classes.cardcontent}>
        <Typography
          variant="h5"
          onClick={() => navigate(`/view-post/${cardData._id}`)}
          sx={{ cursor: "pointer" }}
        >
          {cardData?.title}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1, color: COLORS.lightGray }}>
          {cardData?.description}
        </Typography>
      </CardContent>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  cardcontainer: {
    maxWidth: "100%",
    height: "452px",
    position: "relative",
    borderRadius: "12px",
    width: "100%",
  },
  cardcontent: {
    position: "absolute",
    bottom: 0,
    height: "117px",
    borderRadius: "12px",
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    margin: "10px",
    color: "black",
    width: "-webkit-fill-available",
  },
}));
